import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container'
import Autocomplete from '@mui/material/Autocomplete';
import SendIcon from '@mui/icons-material/Send'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useAppDispatch, useAppSelector } from "../hooks";
import { BookmarkModel } from '../components/bookmarks/bookmarkModel';
import get_info from '../services/pageinfo'
import get_tags from '../services/tags'
import { useAddBookmarkMutation } from '../components/bookmarks/bookmarksSlice';
import CustomAlert from '../components/Alert';


export default function Create() { 
	const [ addNewBookmark, {isLoading} ] = useAddBookmarkMutation();

  const [title, setTitle] = useState('')
  const [url, setUrl ] = useState('')
  const [tags, setTags ] = useState([''])
  const [description, setDescription] = useState('')
  const [publik, setPublic] = useState(false)
  const [titleError, setTitleError] = useState(false)
  const [urlError, setUrlError] = useState(false)

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError]     = React.useState(false);
  const [openPIError, setOpenPIError] = React.useState(false);
  const [formSent, setFormSent ]      = React.useState( false );
  const [alltags, setAllTags ]        = React.useState( [''])

  const navigate = useNavigate()

  useEffect(() => {
    console.log( "Create: useEffect() ")

    document.title = "Algoretum : Create new bookmark";

    get_tags().then( (tags) => {
      console.log( "Tag number : ", tags.length )
      setAllTags(tags)
    });
  }, []);


  const handleCloseSuccess = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  }; 
  
  const handleCloseError = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  }; 
  
  const checkUrl = async () => {

    try {
      const pageInfo = await get_info(url)
      setTitle( pageInfo.title);
      setDescription( pageInfo.description);
      setTags( pageInfo.tags.split(',') )
    }
    catch( error )
    {
      console.log("Error retrieving page information records!!!");
      setOpenPIError(true);

      setTitle( "");
      setDescription( "");
      setTags( [''] );
    }
  }

  const bmOperationState = "READY"; //useAppSelector( getCurrentState)

  if( formSent ) {

   if( bmOperationState === 'READY') {
    setOpenSuccess( true )
   }
   //if( bmOperationState === 'ERROR') {
   // setOpenError( true )
   //}

   setFormSent( false )
  }

  const handleSubmit = async ( e : React.SyntheticEvent ) => {
    //e.preventDefault()
    setTitleError(false)
    setUrlError(false)

    if (title === '') {
      setTitleError(true)
    }
    if (url === '') {
      setUrlError(true)
    }

    if (title && url ) {
      // Convert to array
      console.log( "Taglist:", tags)
      var tags22 = tags

      for( let i=0 ; i<tags22.length ; i++ ) {
        tags22[i] = tags22[i].trim()
      }

      const newBM : BookmarkModel = {
        url: url,
        title: title,
        description: description,
        public: publik,
        tags : tags22,
        user : '',
        id : '',
        created : ''
      };

      try {	
        await( addNewBookmark( newBM ) ).unwrap();
        navigate("/")
      }
      catch( err ) {
        console.error( "Failed to save bookmark : ", err );
      }

      setFormSent( true )
    }
  }

  const handlePublic = (e :  React.FormEvent<HTMLInputElement> ) => {
    setPublic( e.currentTarget.checked ); //  e.target.checked);
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h6" 
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Create a New Bookmark
      </Typography>
      
      <form noValidate autoComplete="off" >
        <TextField 
          margin="dense"
          onChange={(e) => setUrl(e.target.value)}
          label="Bookmark URL" 
          variant="outlined" 
          color="secondary" 
          fullWidth
          value={url}
          required
          error={urlError}
        />
        <Button onClick={ () => {
          checkUrl()
        }}>
          Check
        </Button>
        <TextField 
          sx={{field: {marginTop: 20, marginBottom: 20, display: 'block' } }}
          onChange={(e) => setTitle(e.target.value)}
          label="Bookmark Title" 
          variant="outlined" 
          color="secondary" 
          margin="dense"
          value={title}
          fullWidth
          required
          error={titleError}
        />
        <TextField 
          margin="dense"
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          variant="outlined"
          color="secondary"
          multiline
          value={description}
          rows={4}
          fullWidth
        />

        <Autocomplete
            multiple
            freeSolo={true}
            id="tags"
            options={alltags}
            value={tags}
            onChange={(event, value) => setTags(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tags"
                placeholder="Tags"
              />
            )}
          />

        <FormControl margin="dense">
          <FormControlLabel control={ <Checkbox onChange= { handlePublic} />} label="Public" />
        </FormControl>

        <div>
        <Button 
          type="button" 
          color="secondary" 
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
          >
          Submit
        </Button>
        </div>

        <Snackbar
          message="Bookmark was added successfully"
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        />
        <CustomAlert timeout={6000} message="ERROR Adding bookmark" open={openError} closeHandler={ () => {setOpenError(false)}} />
        <CustomAlert timeout={6000} message="ERROR retrieving page informtion" open={openPIError} closeHandler={ () => {setOpenPIError(false)}} />
      </form>
    </Container>
  )
}
