export default class Util
{
  static getCookie( cname :string ) 
  {
    let cookieValue = "";
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, cname.length + 1) === (cname + '=')) {
                cookieValue = decodeURIComponent(cookie.substring( cname.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  static setCookie(cname:string, cvalue:string, exdays:number )
  {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    var samesite = "SameSite=Lax";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + samesite;
    //console.log("setCookie : new value : " + document.cookie );
  }

  static deleteCookie(cname : string ) 
  {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  static getUserName()
  {
		const loggedUserJSON = this.getCookie('bookmarkAppUser')
		if (loggedUserJSON) {
			const user = JSON.parse(loggedUserJSON)
      return user.username
    }
    else
      return '';
  }

  static getUser()
  {
		const loggedUserJSON = this.getCookie('bookmarkAppUser')
		if (loggedUserJSON) {
			const user = JSON.parse(loggedUserJSON)
      return user
    }
    else
      return {};
  }

  static getName() : string
  {
		const loggedUserJSON = this.getCookie('bookmarkAppUser')
		if (loggedUserJSON) {
			const user = JSON.parse(loggedUserJSON)
      return user.name
    }
    else
      return '';
  }

  static getToken()
  {
		const loggedUserJSON = this.getCookie('bookmarkAppUser')
		if (loggedUserJSON) {
			const user = JSON.parse(loggedUserJSON)
      return user.token
    }
    else
      return '';
  }



  // Some setting helper functions
  static getPageSize() : number
  {
    const settingsJson = this.getCookie( "Settings");
    if( settingsJson !== undefined && settingsJson !== "") {
      const settings = JSON.parse(settingsJson);

      console.log( "Found pageSize = " + settings.pageSize );
      return parseInt( settings.pageSize );
    }
    else
      return 50;
  }

  static getOrder() : boolean
  {
    const settingsJson = this.getCookie( "Settings");
    if( settingsJson !== undefined && settingsJson !== "") {
      const settings = JSON.parse(settingsJson);
      return settings.orderDecreasing
    }
    else
      return true
  }

  static getMode() : number
  {
    const settingsJson = this.getCookie( "Settings");
    if( settingsJson !== undefined && settingsJson !== "") {
      const settings = JSON.parse(settingsJson);
      return settings.mode;
    }
    else
      return 1;  // Light is default
  }
}
