import { statement } from "@babel/template";
import { createSlice, createAsyncThunk, PayloadAction , createSelector} from "@reduxjs/toolkit";
import { number } from "yargs";
import type { RootState } from '../../store'
import { setPageSize } from "../settings/SettingSlice";

interface OptionsState {
	tags: string,
	search: string,  // General search word
	page: string,
	bookmarkCount: number
};

const initialState: OptionsState = {
	tags: '',
	search: '',
	page: '0',
	bookmarkCount: 0
};

const optionsSlice = createSlice( {
	initialState,
	name: "options",
	reducers: {

		setTags( state, action: PayloadAction<string>) {
			state.tags = action.payload;
		},

		setSearch( state, action: PayloadAction<string>) {
			state.search = action.payload;
		},

		setPage( state, action: PayloadAction<string> ) {
			console.log("setting page to ", action.payload );
			state.page = action.payload;
		},

		setBookmarkCount( state, action: PayloadAction<number> ) {
			state.bookmarkCount = action.payload;
		}

	},
});

export const { setTags, setSearch, setPage, setBookmarkCount } = optionsSlice.actions
export default optionsSlice.reducer;
export const selectTags = (state:RootState) => state.options.tags
export const selectSearch = (state:RootState) => state.options.search
export const selectPageNr = (state:RootState) => state.options.page
export const selectBookmarkCount = (state:RootState) => state.options.bookmarkCount
