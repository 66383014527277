
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../hooks";
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
//import { setDirection, setPageSize } from '../components/settings/SettingActions';


//
// https://react-redux.js.org/using-react-redux/usage-with-typescript
//
const Settings = () => {

	var dispatch = useAppDispatch();

	const sizeChange = (event:SelectChangeEvent ) => {
		console.log( "New pagesize : " , event.target.value);
		//dispatch(setPageSize( parseInt(event.target.value) ) )
	}

	const orderChange = (event:any) => {
		console.log("onChange : order = ", event.target.checked)
		//dispatch( setDirection( event.target.checked ) )
	};

	function orderDecrease() //useAppSelector(state => state.settings.settings.orderDecrease );
	{
	}
	
	function pageSize() // = useAppSelector(state => state.settings.settings.pageSize);
	{
	}

	return(

		<Container maxWidth="sm">
			<Typography
				variant="h6" 
				color="textSecondary"
				component="h2"
				gutterBottom
			>
				General Settings
			</Typography>

			<form noValidate autoComplete="off">

				<FormControlLabel 
					control={
						<Checkbox  id="order" value={orderDecrease} onChange={orderChange} />
					} 
					label="Display order : Increasing" 
				/>

				<Box sx={{ minWidth: 120 }}>

					<FormControl fullWidth>
						<InputLabel id="pagesize-select-label">Pagesize</InputLabel>
						<Select
							labelId="pagesize-select-label"
							id="pagesize-select"
							value= { pageSize.toString() }
							label="Pagesize"
							onChange = {sizeChange}
						>
							<MenuItem value={25}>25</MenuItem>
							<MenuItem value={50}>50</MenuItem>
							<MenuItem value={100}>100</MenuItem>
						</Select>
					</FormControl>

				</Box>

			</form>
		</Container>
	)

}

export default Settings;

