import {
  createSelector,
  createEntityAdapter
} from "@reduxjs/toolkit";

import { EntityState } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks"
import type { BookmarkModel } from "./bookmarkModel"
import { apiSlice } from "../api/apiSlice";
import Util from '../../util'
import type {RootState} from '../../store'
import { selectTags,selectPageNr,selectSearch, setBookmarkCount } from "../options/OptionsSlice";

const bookmarksAdapter = createEntityAdapter<BookmarkModel>({
  sortComparer: (a, b ) => b.created.localeCompare(a.created)
})

const initialState = bookmarksAdapter.getInitialState()

const decreasing = Util.getOrder()
const limit:number = Util.getPageSize();

export const bookmarksSlice = apiSlice.injectEndpoints({

  endpoints: (builder) => ({
      getBookmarks: builder.query< EntityState<BookmarkModel>,string>({
          query: (page: string) => {
            var reqUrl = '/bookmarks'; 
            //const tags = useAppSelector( state => state.options.tags );

            console.log("page : ", page );

            if( false === decreasing )
              reqUrl += "/?sort=inc"
            else
              reqUrl += "/?sort=dec"

            reqUrl += "&page=" + (page ?? '0');
          
            reqUrl += "&maxcnt=" + limit.toString()
            return reqUrl;
          },

          transformResponse: (responseData: BookmarkModel[] ) => {
            //setBookmarkCount( responseData.length );
            console.log("Recourd count : ", responseData.length );
            return bookmarksAdapter.setAll( initialState, responseData );
          },

          providesTags: (result, error, args) =>  //['Bookmarks'],
            result ?
            [
            { type: 'Bookmarks', id: 'LIST' },
            ...result.ids.map( id => ({type: 'Bookmarks' as const, id }))
            ]
            :
            [ {type:'Bookmarks', id:'LIST'} ],

      }),

      getBookmark: builder.query<BookmarkModel,string>({
        query: ( id:string ) => `/bookmarks/${id}`,
        providesTags: (result, error, arg) => [{ type: 'Bookmarks', id: arg }]
      }),

      getBookmarksByTag: builder.query< EntityState<BookmarkModel>, string >({
        query: ( tags:string) => {
          var reqUrl = '/bookmarks';

          if( false === decreasing )
            reqUrl += "/?sort=inc"
          else
            reqUrl += "/?sort=dec"

          if( tags !== '' )
            reqUrl += "&tags=" + tags
          //else
          //  reqUrl += "&maxcnt=" + limit.toString()

          return reqUrl;
        },

        transformResponse: (responseData: BookmarkModel[] ) => {
          return bookmarksAdapter.setAll( initialState, responseData );
        },

        providesTags: (result, error, args) => 
          result ?
          [
         
          ...result.ids.map( id => ({type: 'Bookmarks' as const, id }))
          ]
          :
          [ {type:'Bookmarks', id:'LIST'} ],
      }),

      getBookmarksBySearch: builder.query< EntityState<BookmarkModel>,string>({
        query: ( word:string ) => {
          var reqUrl = '/bookmarks';

          if( false === decreasing )
            reqUrl += "/?sort=inc"
          else
            reqUrl += "/?sort=dec"


          if( word !== '' )
            reqUrl += "&search=" + word
          else
            reqUrl += "&maxcnt=" + limit.toString()

          return reqUrl;
        },

        transformResponse: (responseData: BookmarkModel[] ) => {
          return bookmarksAdapter.setAll( initialState, responseData );
        },

        providesTags: (result, error, args) => 
          result ?
          [
            ...result.ids.map( id => ({type: 'Bookmarks' as const, id }))
          ]
          :
          [ {type:'Bookmarks', id:'LIST'} ],
      }),

      addBookmark: builder.mutation({
        query: initialPost => ({
          url: '/bookmarks',
          method: 'POST',
          body: {
            ...initialPost
          }
        }),

        invalidatesTags: [
          { type:'Bookmarks', id:'LIST' }
        ]
      }),

      updateBookmark: builder.mutation({
        query: initialPost => ({
          url: `/bookmarks/${initialPost.id}`,
          method: 'PUT',
          body: {
            ...initialPost
          }
        }),

        invalidatesTags: (result, error, arg) => [
          { type:'Bookmarks',  id: arg.id }
        ]
      }),

		deleteBookmark: builder.mutation< EntityState<BookmarkModel>, string > ( {
			query: (id : string) => ({
				url: '/bookmarks/',
				method: 'DELETE',
				body: {id}
			}),

      invalidatesTags: ( _result, _error, bmid) => 
        [ 
          { type:'Bookmarks', id:'LIST' }, 
          { type:'Bookmarks', id:bmid }, 
        ]
		}),
  }),
})

export const {
  useGetBookmarksQuery,
  useGetBookmarkQuery,
  useGetBookmarksByTagQuery,
  useGetBookmarksBySearchQuery,
  useAddBookmarkMutation,
  useUpdateBookmarkMutation,
  useDeleteBookmarkMutation,
} = bookmarksSlice


// returns the query result object
export const selectBookmarksResult        = (state:RootState)  => { 
  return bookmarksSlice.endpoints.getBookmarks.select( selectPageNr(state).toString() )(state);
}

export const selectTaggedResult = (state:RootState)  => {
  return bookmarksSlice.endpoints.getBookmarksByTag.select( selectTags(state))(state) 
};

export const selectSearchResult = (state:RootState) => bookmarksSlice.endpoints.getBookmarksBySearch.select(selectSearch(state))(state)

// Creates memoized selector
const selectBookmarksData = createSelector(
  selectBookmarksResult,
  bookmarksResult => bookmarksResult.data // normalized state object with ids & entities
)

export const selectBookmarksByTagData = createSelector(
  selectTaggedResult,
  taggedResult => taggedResult.data
)
export const selectBookmarksBySearchData = createSelector(
  selectSearchResult,
  searchResult => searchResult.data
)

const selectSelf = (state: RootState) => state
type Selector<S> = (state:RootState) => S;

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllBookmarks,
  selectById: selectBookmarkById,
  selectIds: selectBookmarkIds,
  // Pass in a selector that returns the bookmarks slice of state
} = bookmarksAdapter.getSelectors<RootState>( state => selectBookmarksData(state) ?? initialState );

export const {
  selectAll: selectAllBookmarksByTag,
  selectById: selectTaggedById,
} = bookmarksAdapter.getSelectors<RootState>( state => selectBookmarksByTagData(state) ?? initialState );

export const {
  selectById: selectSearchById,
} = bookmarksAdapter.getSelectors<RootState>( state => selectBookmarksBySearchData(state) ?? initialState );
