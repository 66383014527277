import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container'
import SendIcon from '@mui/icons-material/Send'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { addUser } from "../components/users/usersSlice"
import { useAppDispatch, useAppSelector } from "../hooks";
import type { UserModel } from '../components/users/UserModel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export default function CreateUser() { 
  const [username, setUsername] = useState('')
  const [name, setName ] = useState('')
  const [password, setPassword ] = useState('')
  const [password2, setPassword2 ] = useState('')
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError]     = React.useState(false);
  const [formSent, setFormSent ]   = React.useState( false );
  const [usernameError, setUsernameError ] = React.useState(true )
  const [passwordError, setPasswordError ] = React.useState(true )
  const [password2Error, setPassword2Error ] = React.useState(true )
  const [sendDisabled, setSendDisabled] = React.useState(true)

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = "Algoretum : Create new User";   }, []);

  const handleCloseSuccess = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };  

/*
  const bmOperationState = useAppSelector( getCurrentState)

  if( formSent ) {

   if( bmOperationState === 'READY') {
    setOpenSuccess( true )
   }
   if( bmOperationState === 'ERROR') {
    setOpenError( true )
   }

   setFormSent( false )
  } 
 */
  const handleUserChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value); 
    setUsernameError(e.target.value === '');
    setSendDisabled(e.target.value === '' || passwordError || password2Error || password !== password2)
  }
  const handlePasswordChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value); 
    setPasswordError(e.target.value === '');
    setSendDisabled(usernameError || e.target.value === '' || password2Error  || password2 !== e.target.value)
  }
  const handlePassword2Change = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value); 
    setPassword2Error(e.target.value === '');
    setSendDisabled(usernameError || passwordError || e.target.value === '' || password !== e.target.value )
  }

  const handleSubmit = async ( e : React.SyntheticEvent ) => {
    e.preventDefault()
    setUsernameError(username === '')
    setPasswordError(password === '')
    setPassword2Error(password2 === '')

    if ( password2 !== password) {
      setPassword2Error(true)
      setPasswordError(true)
    }

    if ( username && password ) {
      username.trim();
      name.trim();
      password.trim();
      const newUser : UserModel = {
        name: name,
        username: username,
        password: password,
        id : '',
        bookmarks: []
      };

      setUsername('');
      setName('');
      setPassword('')
      setPassword2('')

      dispatch( addUser( newUser));

      setFormSent( true )
    } 
  }

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h6" 
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Create a New User
      </Typography>
      
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          margin="dense"
          onChange={(e) => setName(e.target.value)}
          label="Name" 
          variant="outlined" 
          color="secondary" 
          fullWidth
        />
        <TextField
          sx={{field: {marginTop: 20, marginBottom: 20, display: 'block' } }}
          onChange={handleUserChange}
          label="User Name" 
          variant="outlined" 
          color="secondary" 
          margin="dense"
          fullWidth
          required
          error={usernameError}
        />

        <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            onChange={handlePasswordChange}
            error={passwordError}
            type={showPassword ? 'text' : 'password'}
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  //onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <FormControl sx={{ mt: 1, mb:1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Reenter Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword2 ? 'text' : 'password'}
              onChange={handlePassword2Change}
              error={password2Error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password2"
            /> 
        </FormControl>

        <div>
        <Button 
          type="submit" 
          disabled={sendDisabled}
          color="secondary" 
          variant="contained"
          endIcon={<SendIcon />}
          >
          Submit
        </Button>
        </div>

        <Snackbar
          message="User was added successfully"
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        />
        <Snackbar
          message="ERROR adding User!!"
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        />

      </form>

      
    </Container>
  )
}
