import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Util from '../../util'
import type { UserLogin, UserInfo } from '../../components/users/UserModel'

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.REACT_APP_BACKEND,
        prepareHeaders: (headers: Headers, { getState } ) => {

            const loggedUserJSON = Util.getCookie('bookmarkAppUser')
            if (loggedUserJSON) {
                const user = JSON.parse(loggedUserJSON)
                headers.set( 'Authorization', 'bearer ' + user.token );
                //headers.set('Access-Control-Allow-Origin', '*');
            }

            return headers;
        }
    }),
    tagTypes: ['Bookmarks' ], //, 'User'],
    //endpoints: builder => ({})

    endpoints: (builder) => ({
        userLogin: builder.mutation<UserInfo, UserLogin>({
          query: (credentials) => ({
            url: 'login',
            method: 'POST',
            body: credentials,
          }),
        }),
        protected: builder.mutation<{ message: string }, void>({
          query: () => 'protected',
        }),
      }),    
})

export const { useUserLoginMutation, useProtectedMutation } = apiSlice;
