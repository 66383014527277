import React from 'react'
import { useParams } from 'react-router-dom'
import {useSelector } from 'react-redux'
import Bookmark from './Bookmark' 
import Container from '@mui/material/Container'
import Masonry from 'react-masonry-css'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import NextIcon from '@mui/icons-material/KeyboardArrowRight';
import PrevIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useAppDispatch} from "../../hooks";
import { useDeleteBookmarkMutation } from "./bookmarksSlice";
import { useGetBookmarksQuery, selectBookmarkIds} from "./bookmarksSlice";
import {setPage} from '../options/OptionsSlice';
import Util from '../../util';

export default function Bookmarks() {
	const dispatch = useAppDispatch();

	const [deleteBM] = useDeleteBookmarkMutation();
	const {page} = useParams();
	const n = page ? parseInt( page ) : 0;
	const nextpage = "/page/"+String(n+1);
	const prevpage = "/page/"+String(n-1);

	const pageSize:number = Util.getPageSize();
	const user:string =  Util.getUserName() 

	const {
        isLoading,
        isSuccess,
        isError,
    } = useGetBookmarksQuery(page ?? '0')

	const orderedBookmarkIds = useSelector(selectBookmarkIds);
	var haveNext = pageSize === orderedBookmarkIds.length;

	const breakpoints = {
		default: 3,
		1100: 2,
		700: 1
	};

	React.useEffect(() => {
		dispatch( setPage(page ?? '0') );
		document.title = "Algoretum : Book Marx";  
	}, [])

	const delBM = async ( bmID : string) => {
		try {
			await deleteBM( bmID ).unwrap()

			//refetch() // TODO : SHouldn't be necessary !!!
      		console.log( "Bookmark was deleted successfully")
      		setTimeout( () => {alert( "Bookmark was deleted successfully !") } )
		}
		catch( err ) {
			console.log( "Bookmark deleted ERROR = ", err)
			setTimeout( () => {alert( "Failed to deleted bookmark:" + err) } )
		}
	}

	const showBookmarks = () => {

		let content;
		if (isLoading) 
		{
			content = <div> <p key="1">Loading ...</p> </div>
		}
		else if (isSuccess) 
		{
			content =  orderedBookmarkIds.map( bmId => 
				<div key={bmId}>
					<Bookmark id = {bmId.toString() } deleteHandler={delBM} user={user} />
				</div>
			)
		}
		else if (isError) {
			content = 
				<div>
					<p key="2">Some error occurred</p>
				</div>
		}

		return content;
	}

    return (
		<Box  >
		  <Container>
			  <Masonry 
				  breakpointCols={breakpoints}
				  className="my-masonry-grid"
				  columnClassName="my-masonry-grid_column"        
			  >
				  { showBookmarks() }
			  </Masonry>
		  </Container>
 
		{  ( parseInt(page ?? '0') > 0) ?
		  <Fab color="secondary" aria-label="left" 
		     	sx={{
              		position: 'fixed',
              		bottom: (theme) => theme.spacing(18),
              		right: (theme) => theme.spacing(2),
             	}}
			 	component={Link}
			 	href={prevpage}
			 >
			   < PrevIcon />
		  </Fab>
			 : ''
		}

		{ ( haveNext )?

		  <Fab color="secondary" aria-label="right" 
		     	sx={{
              		position: 'fixed',
              		bottom: (theme) => theme.spacing(10),
              		right: (theme) => theme.spacing(2),
             	}}
			 	component={Link}
			 	href={nextpage}
			 >
			   <NextIcon />
		  </Fab>
		    : ''
		}

		{ (user === '') ? '' :
		  <Fab color="secondary" aria-label="add" 
		     	sx={{
              		position: 'fixed',
              		bottom: (theme) => theme.spacing(2),
              		right: (theme) => theme.spacing(2),
             	}}
			 	component={Link}
			 	href="/create"
			 >
			   <AddIcon />
		  </Fab>
		}

		  </Box>
	  )

}
