import { useNavigate } from 'react-router-dom'
import { IconButton, List, ListItemIcon, ListItemText } from '@mui/material';
import {  AddCircleOutlineOutlined, SubjectOutlined } from '@mui/icons-material'
import ListItemButton from '@mui/material/ListItemButton';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import store from '../store'
import Util from '../util'

import { useDrawerContext } from "./DrawerContext";

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));


const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

export default function MyDrawer() {

	const { isOpened, toggleIsOpened } = useDrawerContext();
	
	const theme = useTheme();
	const navigate = useNavigate()

	const handleDrawerOpen = () => {
		toggleIsOpened(true);
	};

	const handleDrawerClose = () => {
		toggleIsOpened(false);
	};

	var user = Util.getUserName()  //store.getState().user.user.name;

	return (
	
		<StyledDrawer variant="permanent" open={isOpened}>
			<DrawerHeader>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</DrawerHeader>
			<Divider />

			<List>

				<ListItemButton
					key="Bookmarks"
					disabled={ user === ''}
					onClick={() => navigate('/')}
				>
					<ListItemIcon><SubjectOutlined color="secondary" /></ListItemIcon>
					<ListItemText primary={"Bookmarks"} />
				</ListItemButton>

				<ListItemButton
					disabled={ user === ''}
					key="Add Bookmark"
					onClick={() => navigate('/create')}
				>
					<ListItemIcon><AddCircleOutlineOutlined color="secondary" /></ListItemIcon>
					<ListItemText primary={"Add Bookmark"} />
				</ListItemButton>

				<ListItemButton  key="Settings" disabled={ user === ''} onClick={() => navigate('/settings')}  >
					<ListItemIcon><SettingsApplicationsIcon color="secondary" /></ListItemIcon>
					<ListItemText primary={"Settings"} />
				</ListItemButton>
			</List>

		</StyledDrawer>	
	)
}
