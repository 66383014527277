import './App.css';
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Create from './pages/Create';
import { Navigate} from 'react-router-dom'
import Edit from './pages/Edit'
import Login from './pages/Login'
import Settings from './pages/Settings'
import Layout from './components/Layout';
import Bookmarks from './components/bookmarks/Bookmarks';
import BookmarksByTag from './components/bookmarks/BookmarksByTag'
import BookmarksBySearch from './components/bookmarks/BookmarksBySearch'
import CreateUser from './pages/CreateUser';
import CurrentUserAccount from './pages/CurrentUserAccount'
import Main from './components/Main'
//import { useAuth } from './hooks/useAuth'
import Util from './util';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const md=Util.getMode();
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: md == 0 ? (prefersDarkMode ? 'dark' : 'light') : (md == 1 ? 'light' : 'dark'),
        },
        typography: {
          fontFamily: 'Quicksand',
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 600,
          fontWeightBold: 700,
        }        
      }),
    [prefersDarkMode],
  );

  const [user, setUser ] = useState('');
  
  useEffect(() => {
	  const storeduser:string =  Util.getUserName() 
	  console.log( "app : user = ",storeduser);
	  setUser( storeduser);
  }, [])
  //const auth = useAuth()

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
			  {
          <Routes>
					  <Route index element =  <Main /> />
					  <Route path="login" element={<Login />} />
					  <Route path="page/:page" element = { <Bookmarks />} />
					  <Route path="tag/:tag" element={ <BookmarksByTag />} />
					  <Route path="search/:searchWord" element={ <BookmarksBySearch />} />
					  <Route path="edit/:id" element={ <Edit />   } />
					  <Route path="settings" element={ <Settings /> } />
					  <Route path="edit_user" element={ <CurrentUserAccount />   } />
					  <Route path="create" element={ <Create />   } />
					  <Route path='*' element={<Navigate to='/' />} />
            <Route path="create_user" element={ <CreateUser />   } />
				  </Routes>
			  }
        </Layout>  
      </Router>
    </ThemeProvider>
  );
};
