import { useSelector } from "react-redux";
import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import { CardContent, CardActions }  from '@mui/material/';
import IconButton from '@mui/material/IconButton'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import ChipList from './ChipList'
import {selectSearchById} from './bookmarksSlice'
import {BookmarkModel} from './bookmarkModel'
import type {RootState} from '../../store'
import {useAppSelector} from '../../hooks'

interface BookmarkProps {
	id: string;
	deleteHandler( id : string) : void ;
};

export default function  BookmarkBySearch(props : BookmarkProps) 
{
  const bm = useAppSelector(state => selectSearchById(state,  props.id) ??
      {created:'',description:'',id:'',public:false, tags:[],title:'',url:'',user:''}  );
 
  var fullDesc = bm?.description ?? '';
  fullDesc += "\n   Created:";
  fullDesc += bm?.created

  let content;

    content = (
      <div>
      <Card elevation={3}>
        <CardContent>
          <Tooltip title={<Typography fontSize={14}>{fullDesc}</Typography >}>
            <Link href={ bm.url} underline="hover">
              { bm.title }
            </Link>
          </Tooltip>

          <ChipList adat ={bm.tags} />

        </CardContent>

        <CardActions>
          <Tooltip title='Delete'>
              <IconButton onClick={ () => {  props.deleteHandler(props.id ) }} >
                <DeleteOutlined  />
              </IconButton>
          </Tooltip>
        </CardActions>
     
      </Card>
    </div>      
    )

  return content;
}
