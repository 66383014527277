
import axios from 'axios'

const baseUrl = '/api/pageinfo'

export interface PageInfo {
  title: string,
  description : string;
  tags : string;
};


async function get_info(url : string ) : Promise<PageInfo>
{
  let req = "/?url="+url
  const request = axios.get(baseUrl+req);
  return request.then(response => response.data) 
}

export default get_info
