
import React from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks'
import BookmarkByTag from './BookmarkByTag' 
import Container from '@mui/material/Container'
import Masonry from 'react-masonry-css'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close'
import { useParams } from 'react-router-dom'
import { useDeleteBookmarkMutation } from "./bookmarksSlice";
import { useGetBookmarksByTagQuery} from "./bookmarksSlice";
import { setTags} from "../options/OptionsSlice"

export default function BookmarksByTag() {

	const [deleteBM] = useDeleteBookmarkMutation();
    const {tag} = useParams();

	const dispatch = useAppDispatch();

	const {
		data: bmList,
        isLoading,
        isSuccess,
        isError,
        error,
		refetch
    } = useGetBookmarksByTagQuery(tag ? tag : '')

	React.useEffect(() => {
		dispatch( setTags(tag ?? '') )
		document.title = "Algoretum : Tag search for " + tag;
	}, [])

	const breakpoints = {
		default: 3,
		1100: 2,
		700: 1
	};

	const delBM = async ( bmID : string) => {
		try {
			await deleteBM( bmID ).unwrap()

			refetch() // TODO : SHouldn't be necessary !!!
      		console.log( "Bookmark was deleted successfully")
      		setTimeout( () => {alert( "Bookmark was deleted successfully !") } )
		}
		catch( err ) {
			console.log( "Bookmark deleted ERROR = ", err)
			setTimeout( () => {alert( "Failed to deleted bookmark:" + err) } )
		}
	} 

	const showBookmarks = () => {

		let content;
		if (isLoading) 
		{
			content = <div> <p>Loading ...</p> </div>
		}
		else if (isSuccess) 
		{
			const { ids } = bmList;

			//if( entities === undefined || entities === null ) {
			//	throw new TypeError("BOOKMARKS!!")
			//}
		    content =  ids.map( idb => {
				//if( entities[idb] === undefined   || entities[idb] === null) {
				//	throw new TypeError("BOOKMARKS!!")
				//}
	
				  return <BookmarkByTag key={idb} id = {idb.toString()} deleteHandler={delBM} />
			  })
			
		} else if (isError) {
			content = 
				<div>
					<p>Some error occurred</p>
				</div>
		}

		return content;
	}

    return (
		<Box  >
		  <Container>
			  <Masonry 
				  breakpointCols={breakpoints}
				  className="my-masonry-grid"
				  columnClassName="my-masonry-grid_column"        
			  >
				  { showBookmarks() }
			  </Masonry>


		  </Container>
		  <Fab color="secondary" variant="extended" 
		     sx={{
              position: 'fixed',
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
             }}
			 component={Link}
			 href="/"
			 >
			   <CloseIcon />
			   {tag}
			</Fab>

		  </Box>
	  )

}
