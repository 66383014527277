import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {apiSlice} from "./components/api/apiSlice";
import usersSlice from './components/users/usersSlice';
import optionsSlice from './components/options/OptionsSlice';

const store = configureStore( {
	reducer: {
		[ apiSlice.reducerPath]: apiSlice.reducer,
		user: usersSlice.reducer,
		options : optionsSlice,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat( apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

export default store
