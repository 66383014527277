
import axios from 'axios'

const baseUrl = '/api/tags'

//export interface TagsData {
//  name: string,
//};

export async function get_tags() : Promise< string[]>
{
    const request = axios.get(baseUrl)
    return request.then(response => response.data )
}

export default get_tags
