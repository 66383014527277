
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material'
import React from 'react'
import { format } from 'date-fns'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';

//import LoginDialog from './LoginDialog';
import SettingsDialog from './SettingsDlg'
//import bookmarkService from '../services/bookmarks'
import { useAppDispatch } from "../hooks";
import SearchBox from './Search'
import { useDrawerContext } from "./DrawerContext";
import Util from '../util';
//import usersSlice from './users/usersSlice';
import { useGetBookmarksQuery } from './bookmarks/bookmarksSlice';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const drawerWidth = 240;
//export const userActions = usersSlice.actions;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));


const useStyles = makeStyles((theme? : any) => {
	return {
		page: {
			background: '#f9f9f9',
			width: '100%',
			padding: theme.spacing(3),
		},
		root: {
			display: 'flex',
		},
		drawer: {
			width: drawerWidth,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		active: {
			background: '#f4f4f4'
		},
		title: {
			padding: theme.spacing(2),
		},
		appBar: {
			//width: `calc(100% - ${drawerWidth}px)`,
			//marginLeft: drawerWidth,
			sx: `calc(100% - ${drawerWidth}px)`, ml: drawerWidth,
		},
		date: {
			flexGrow: 1
		},
		toolbar: theme.mixins.toolbar,
		avatar: {
			marginLeft: theme.spacing(2)
		}
	}
})

export default function MyAppBar() {

	const { data, refetch  } = useGetBookmarksQuery('0')
	//const dispatch = useAppDispatch();

	const classes = useStyles()
	const { isOpened, toggleIsOpened } = useDrawerContext();
	//const [loginOpen, setLoginOpen] = React.useState(false);
	const [settingsOpen, setSettingsOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	//const [page, setPageNr] = React.useState(0);
	
	const menuOpen = Boolean(anchorEl);

	//var util = new Util();
/*
	React.useEffect(() => {
		//var util = new Util()

		const loggedUserJSON = util.getCookie('bookmarkAppUser')

		if (loggedUserJSON) {
			const user = JSON.parse(loggedUserJSON)
			console.log( "Setting existing user token!!")
			bookmarkService.setToken(user.token)
		}

		//dispatch( setPage( page) );
	}, [])
*/
	//coust theme = useTheme();
	const navigate = useNavigate()
	const handleMenu = ( event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};    


	const handleDrawerOpen = () => {
		toggleIsOpened(true);
	};

	const handleDrawerClose = () => {
		toggleIsOpened(false);
	};

	const handleSettingsClick = () => {
		setSettingsOpen(true);
		handleMenuClose();
	}
	const handleAccountClick = () => {
		navigate('/edit_user');
		handleMenuClose();
	}

	/*
	 *  Logging current user OUT
	 */ 
	const handleMenuLogout = () => {
		setAnchorEl(null);
		console.log("Deleting user cookie/token !!!")
		//dispatch( clearUser() );
		//dispatch( clearBookmarks() )

		Util.deleteCookie( "bookmarkAppUser" );
		navigate('/login');
		refetch();
	};

	const location = useLocation()

	const searchEnter = (word: string) => {
		
		navigate( "/search/" + word ); 
	}

	const pageChangeHandler = (event: React.ChangeEvent<unknown>, page: number) =>
	{
		console.log("Change to page",page);

		//setPageNr(page);
	}

	const user:string =  Util.getUserName() 
	//console.log( "User = ", user );

	return (
		<div>
			<StyledAppBar position="fixed" open={isOpened}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...( isOpened && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>


					<Typography className={classes.date} >
						Bookmarks DB,    Today is the {format(new Date(), 'do MMMM Y')}
					</Typography>

					<SearchBox disabled={location.pathname.substring(0,5) != '/page/' || user===''} onEnter={searchEnter}/>

					<Box sx={{ flexGrow: 1 }} />

					{ ( user !=='') ?

						<div>
							<IconButton
								size="medium"
								edge="end"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>

							

							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={ menuOpen }
								onClose={handleMenuClose}
							>
								<MenuItem onClick={ handleAccountClick }>Account</MenuItem>
								<MenuItem onClick={ handleSettingsClick }>Settings</MenuItem>
								<MenuItem onClick={ handleMenuLogout}>Logout</MenuItem>
							</Menu> 
						</div>
						: ''
					}
				</Toolbar>
			</StyledAppBar>
			<SettingsDialog open={settingsOpen} handleClose={ () => setSettingsOpen(false) } setOpen={ setSettingsOpen } />
		</div>
	)
	//		<LoginDialog open={loginOpen} handleClose={ () => setLoginOpen(false) } setOpen={ setLoginOpen } refetch={ () => refetch() }/>
}

