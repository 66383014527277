import React from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import Chip from "@mui/material/Chip"

interface ChipListProps {
    adat : string[];
}


export default function ChipList( props: ChipListProps ) 
{
  return (
      <div>
          {props.adat.map( (label) => 
            { const full = '/tag/' + label;
             return (<Chip  size="small" key={label } clickable label={ label }  component="a" href={full} /> ) }
          )}
      </div>
  )
}
