
import React from 'react'
import BookmarkBySearch from './BookmarkBySearch' 
import Container from '@mui/material/Container'
import Masonry from 'react-masonry-css'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useDeleteBookmarkMutation } from "./bookmarksSlice";
import { useGetBookmarksBySearchQuery} from "./bookmarksSlice";
import { setSearch} from "../options/OptionsSlice"


export default function BookmarksBySearch() {

	const [deleteBM] = useDeleteBookmarkMutation();
    const {searchWord} = useParams();
	const dispatch = useAppDispatch();

	const {
		data: bmList,
        isLoading,
        isSuccess,
        isError,
        error,
		refetch
    } = useGetBookmarksBySearchQuery(searchWord ? searchWord : '')

//	dispatch( setSearch(searchWord ?? '') )

	const breakpoints = {
		default: 3,
		1100: 2,
		700: 1
	};

	React.useEffect(() => {
		dispatch( setSearch(searchWord ?? '') )
		document.title = "Algoretum : Search results for " + searchWord;
	}, [])

	const delBM = async ( bmID : string) => {
		try {
			await deleteBM( bmID ).unwrap()

			refetch() // TODO : SHouldn't be necessary !!!
      		console.log( "Bookmark was deleted successfully")
      		setTimeout( () => {alert( "Bookmark was deleted successfully !") } )
		}
		catch( err ) {
			console.log( "Bookmark deleted ERROR = ", err)
			setTimeout( () => {alert( "Failed to deleted bookmark:" + err) } )
		}
	}

	const showBookmarks = () => {

		let content;
		if (isLoading) 
		{
			content = <div> <p>Loading ...</p> </div>
		}
		else if (isSuccess) 
		{
			const {ids} = bmList;

			content =  ids.map( bmId => 
				<BookmarkBySearch key={bmId} id = {bmId.toString() } deleteHandler={delBM} />
			)
			
		} else if (isError) {
			content = 
				<div>
					<p>Some error occurred</p>
				</div>
		}

		return content;
	}

    return (
		<Box  >
		  <Container>
			  <Masonry 
				  breakpointCols={breakpoints}
				  className="my-masonry-grid"
				  columnClassName="my-masonry-grid_column"        
			  >
				  { showBookmarks() }
			  </Masonry>


		  </Container>
		  <Fab color="secondary" variant="extended" 
		     sx={{
              position: 'fixed',
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
             }}
			 component={Link}
			 href="/"
			 >
			   <CloseIcon />
			   {searchWord}
			</Fab>

		  </Box>
	  )

}
