import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import {HighlightOff} from '@mui/icons-material';

interface SearchProps {
	onEnter( word: string ): void ;
	disabled : boolean
}

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

const StyledOff = styled(HighlightOff)(({theme}) => ({
	color: 'secondary',
	paddingRight: `calc(${theme.spacing(1)})`,
}));

interface State {
	searchWord: string;
}

export default function SearchBox( props : SearchProps ) {

	const [values, setValues ] = React.useState<State>({
		searchWord: ''
	});

	const handleChange = (prop: keyof State) => 
		(event: React.ChangeEvent<HTMLInputElement>) => {
		setValues( { ...values, [prop]: event.target.value} );
	}

	const handleClearClick = () => {
		setValues({
			searchWord: ''
		});
	};

	const onKeyPress = (event : React.KeyboardEvent<HTMLInputElement> ) => {
		if( event.keyCode === 13 ) {
			props.onEnter( values.searchWord );
		}
	}

	return (
		<Search>
			<SearchIconWrapper>
				<SearchIcon />
			</SearchIconWrapper>
			<StyledInputBase
				placeholder="Search…"
				onChange={handleChange('searchWord')}
				onKeyDown={onKeyPress}
				disabled={props.disabled}
				inputProps={{ 'aria-label': 'search' }}
				value={values.searchWord}

				endAdornment={
					<InputAdornment position="end" >
						<IconButton
							edge="end"
							onClick={handleClearClick}
						>
							<StyledOff/>
						</IconButton>
					</InputAdornment>
				}
			/>
		</Search>        
	)

}
