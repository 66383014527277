import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container'
import SendIcon from '@mui/icons-material/Send'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { updateUser } from "../components/users/usersSlice"
import { useAppDispatch } from "../hooks";
import type { UserUpdateModel } from '../components/users/UserModel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Util from '../util'

export default function CurrentUserAccount() { 
  const [username, setUsername] = useState(Util.getUserName())
  const [name, setName ] = useState(Util.getName())
  const [currpassword, setCurrPassword ] = useState('')
  const [password, setPassword ] = useState('')
  const [password2, setPassword2 ] = useState('')
  const [showCurrPassword, seShowCurrPassword ] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError]     = React.useState(false);
  const [formSent, setFormSent ]   = React.useState( false );
  const [userError, setUserError ] = React.useState(false)
  const [usernameError, setUsernameError ] = React.useState(false)
  const [currPasswordError, setCurrPasswordError ] = React.useState(true )
  const [passwordError, setPasswordError ] = React.useState(true )
  const [password2Error, setPassword2Error ] = React.useState(true )
  const [sendDisabled, setSendDisabled] = React.useState(true)

  const dispatch = useAppDispatch();

  const handleCloseSuccess = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleClickShowCurrPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleUserChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value); 
    setUserError(e.target.value === '');
    setSendDisabled(e.target.value === '' || userError || passwordError || password2Error || password !== password2)
  }

  const handleUserNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value); 
    setUsernameError(e.target.value === '');
    setSendDisabled(e.target.value === '' || usernameError || passwordError || password2Error || password !== password2)
  }

  const handleCurrPasswordChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCurrPassword(e.target.value); 
    setCurrPasswordError(e.target.value === '');
    setSendDisabled( userError || usernameError || e.target.value === '' || password2Error  || passwordError)
  }
  const handlePasswordChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value); 
    setPasswordError(e.target.value === '');
    setSendDisabled(usernameError || e.target.value === '' || password2Error  || password2 !== e.target.value)
  }
  const handlePassword2Change = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value); 
    setPassword2Error(e.target.value === '');
    setSendDisabled(usernameError || passwordError || e.target.value === '' || password !== e.target.value )
  }

  const handleSubmit = async ( e : React.SyntheticEvent ) => {
    e.preventDefault()
    setUsernameError(username === '')
    setPasswordError(password === '')
    setPassword2Error(password2 === '')

    if ( password2 !== password) {
      setPassword2Error(true)
      setPasswordError(true)
    }

    if ( username && password ) {
      username.trim();
      name.trim();
      currpassword.trim();
      password.trim();
      const newUser : UserUpdateModel = {
        name: name,
        username: username,
        password: currpassword,
        newpassword: password,
        id : '',
        bookmarks: []
      };

      setUsername('');
      setName('');
      setCurrPassword('')
      setPassword('')
      setPassword2('')

      const savedUser = await dispatch( updateUser( newUser));
      Util.setCookie("bookmarkAppUser",JSON.stringify(savedUser),1 );

      setFormSent( true )
    } 
  }

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h6" 
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        User Account
      </Typography>
      
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          margin="dense"
          defaultValue={name}
          value={name}
          onChange={handleUserChange}
          label="Name" 
          variant="outlined" 
          color="secondary" 
          fullWidth
        />
        <TextField
          sx={{field: {marginTop: 20, marginBottom: 20, display: 'block' } }}
          onChange={handleUserNameChange}
          label="User Name" 
          defaultValue={username}
          value={username}
          variant="outlined" 
          color="secondary" 
          margin="dense"
          fullWidth
          error={usernameError}
        />

        <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required>
          <InputLabel>Current Password</InputLabel>
          <OutlinedInput
            id="current-password"
            onChange={handleCurrPasswordChange}
            error={currPasswordError}
            type={showCurrPassword ? 'text' : 'password'}
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle curr password visibility"
                  onClick={handleClickShowCurrPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="CurrPassword"
          />
        </FormControl>

        <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required>
          <InputLabel >New Password</InputLabel>
          <OutlinedInput
            id="new-password"
            onChange={handlePasswordChange}
            error={passwordError}
            type={showPassword ? 'text' : 'password'}
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <FormControl sx={{ mt: 1, mb:1 }} fullWidth variant="outlined">
          <InputLabel >Reenter New Password</InputLabel>
            <OutlinedInput
              id="new-password2"
              type={showPassword2 ? 'text' : 'password'}
              onChange={handlePassword2Change}
              error={password2Error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password2"
            /> 
        </FormControl>

        <div>
        <Button 
          type="submit" 
          disabled={sendDisabled}
          color="secondary" 
          variant="contained"
          endIcon={<SendIcon />}
          >
          Submit
        </Button>
        </div>

        <Snackbar
          message="User was added successfully"
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        />
        <Snackbar
          message="ERROR adding User!!"
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        />

      </form>

      
    </Container>
  )
}
