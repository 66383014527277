import React, { useState, useEffect } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setUser } from '../components/users/usersSlice';
import type { UserLogin } from '../components/users/UserModel'
import Util from '../util'
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../hooks'
import { useUserLoginMutation } from '../components/api/apiSlice';
import { useGetBookmarksQuery } from '../components/bookmarks/bookmarksSlice';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={process.env.REACT_APP_HOMEPAGE}>
         {process.env.REACT_APP_TITLE}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const [redir, setRedir] = useState(false)
	const { data, refetch  } = useGetBookmarksQuery('0')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ userLogin, { isLoading }] = useUserLoginMutation()

  useEffect(() => {
    // Checking if user is not loggedIn
    // From https://www.copycat.dev/blog/react-router-redirect/

    const storeduser:string =  Util.getUserName() 
    console.log( "app : user = ",storeduser);
    if (redir ){
      console.log("Navigating away...");
      navigate("/page/0");
      refetch();
    }
    else {
      if( storeduser != '' ){
        refetch();
        navigate("/page/0");
      }
    }
  }, [navigate, redir]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const loginData : UserLogin = { username : data.get("email") as string, password : data.get('password') as string };

    const userData = await userLogin( loginData ).unwrap()

    //const Data = await dispatch( loginUser( loginData) );
    //const userData:any = Data.payload
    Util.setCookie("bookmarkAppUser",JSON.stringify(userData),1 );
    // store user's token in local storage
    localStorage.setItem('userToken', userData.token)
    setRedir(true);    
    console.log( userData );
    setUser( userData)
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
 
  return (   
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
			     type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  </InputAdornment>
                ),
              }}
    
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
