
import axios from 'axios'
import Util from '../util';
import {UserModel, UserInfo, UserLogin, UserUpdateModel} from '../components/users/UserModel';

const baseUrl:string = process.env.REACT_APP_BACKEND || "";

async function create(newObject : UserModel ) : Promise<UserModel>
{
  const loggedUserJSON = Util.getCookie('bookmarkAppUser')
  let headers = {}
  if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      headers = {'Authorization': 'bearer ' + user.token} ;
  }

  const response = await axios.post(baseUrl, newObject, { headers:headers } );
  return  response.data 
}

async function user_login(userInfo : UserLogin ) : Promise<UserInfo>
{
  const response = await axios.post(baseUrl+"/login", userInfo);
  return  response.data; 
}

async function update(newObject : UserUpdateModel ) : Promise<UserModel>
{
  const loggedUser = Util.getUser();
  let headers = {}
  if (loggedUser) {
      headers = {'Authorization': 'bearer ' + loggedUser.token} ;
      newObject.id = loggedUser.id;
  }

  const response = await axios.put(baseUrl+"/", newObject, { headers:headers } );
  return  response.data 
}

export default {create, user_login, update}
