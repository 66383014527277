import React from 'react'
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';
import { Button, FormControl } from '@mui/material'
import { useAppDispatch, useAppSelector } from "../hooks";
import { storeSettings } from '../components/settings/SettingActions';
import SettingsModel from './settings/SettingsModel'
import Util from '../util'

export interface SettingsProps {
	setOpen( op: boolean ): void ,
	handleClose() : void,
	open : boolean
}

export default function SettingsDialog(props : SettingsProps ) {

	const [order,setOrder] = React.useState(false)
	const [pageSize, setPageSize] = React.useState(50)
	const [mode, setMode ] = React.useState(2)

	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	//const orderDecrease =  useAppSelector(state => state.settings.settings.orderDecrease );
	//const pageSize = useAppSelector(state => state.settings.settings.pageSize);
	
	React.useEffect(() => {
		console.log("useEffect runs")

		const settingsJSON = Util.getCookie('Settings')

		if (settingsJSON) {
			const stg:SettingsModel = JSON.parse(settingsJSON)
			setPageSize( stg.pageSize )
			setOrder( stg.orderDecreasing )
			setMode(stg.mode)
		}
	}, [])

	const orderChange = (event:SelectChangeEvent ) => {
		setOrder( event.target.value === "true"  )
		console.log( "New order : " , event.target.value);
	}

	const sizeChange = (event:SelectChangeEvent ) => {
		console.log( "New pagesize : " , event.target.value);

		//dispatch(setPageSize( parseInt(event.target.value) ) )
		setPageSize( parseInt(event.target.value ) )
	}
	const modeChange = (event:SelectChangeEvent ) => {
		console.log( "New Mode : " , event.target.value);

		//dispatch(setPageSize( parseInt(event.target.value) ) )
		setMode( parseInt(event.target.value ) )
	}

	const handleClickApply = async (event: any) => {
		event.preventDefault()
		try {
			//console.log( "User email : ", email )
			//console.log( "Password   : ", password )

			props.setOpen(false);
			const st : SettingsModel =  {
				pageSize: pageSize,
				orderDecreasing : order,
				mode : mode
			}
			dispatch( storeSettings( st ) );
			navigate('/')
		} catch (exception) {
			alert('Wrong credentials')
			console.log('Wrong credentials')
		}
	}

	return (  

		<Dialog open={props.open} onClose={props.handleClose}>
			<DialogTitle>Settings</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Profile Setting values
				</DialogContentText>

				<Box
					noValidate
					component="form"
					sx = {{
						display: 'flex',
						flexDirection: 'column',
						m: 'auto',
						width: 'fit-content',
					}}
				>
					<FormControl  sx={{ mt: 2, minWidth: 120 }}  >

						<InputLabel id="order-select-label">Order</InputLabel>
						<Select
							labelId="order-select-label"
							id="order-select"
							value= { order.toString() }
							label="Order"
							onChange={orderChange }
						>
							<MenuItem value="true">Start with newest</MenuItem>
							<MenuItem value="false">Start with oldest</MenuItem>
						</Select>
					</FormControl>
					<FormControl  sx={{ mt: 2, minWidth: 120 }}  >

						<InputLabel id="pagesize-select-label">Pagesize</InputLabel>
						<Select
							labelId="pagesize-select-label"
							id="pagesize-select"
							value= { pageSize.toString() }
							label="Pagesize"
							onChange={sizeChange }
						>
							<MenuItem value={25}>TwentyFive</MenuItem>
							<MenuItem value={50}>Fifty</MenuItem>
							<MenuItem value={100}>Hundrend</MenuItem>
						</Select>
					</FormControl>

					<FormControl  sx={{ mt: 2, minWidth: 120 }}  >

						<InputLabel id="pagesize-select-label">UI Mode</InputLabel>
						<Select
							labelId="pagesize-select-label"
							id="mode-select"
							value= { mode.toString() }
							label="UIMode"
							onChange={modeChange }
						>
							<MenuItem value={0}>Browser</MenuItem>
							<MenuItem value={1}>Light</MenuItem>
							<MenuItem value={2}>Dark</MenuItem>
						</Select>
					</FormControl>
				</Box>

			</DialogContent>

			<DialogActions>
				<Button onClick={ props.handleClose }>Cancel</Button>
				<Button onClick={ handleClickApply}>Apply</Button>
			</DialogActions>
		</Dialog>
	)
}
