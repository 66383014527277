import {AppDispatch, RootState } from '../../store'
import SettingsModel from './SettingsModel'
import Util from '../../util'

export const storeSettings = ( stg: SettingsModel ) => {
	return async(dispatch: AppDispatch) => {
		Util.setCookie( "Settings",JSON.stringify( stg), 1 );
	}
}

export const loadSettings = () => {
	return async(dispatch: AppDispatch) => {
		return Util.getCookie("Settings");
	}	
}
