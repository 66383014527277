import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';

import { useAppDispatch } from "../hooks";
import Drawer from "./Drawer";
import {DrawerContextProvider} from './DrawerContext';
import AppBar from "./AppBar"

const drawerWidth = 240

const useStyles = makeStyles((theme? : any) => {
	return {
		page: {
			//background: '#f9f9f9',
			width: '100%',
			padding: theme.spacing(3),
		},
		root: {
			display: 'flex',
		},
		drawer: {
			width: drawerWidth,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		//active: {
		//	background: '#f4f4f4'
		//},
		title: {
			padding: theme.spacing(2),
		},
		appBar: {
			//width: `calc(100% - ${drawerWidth}px)`,
			//marginLeft: drawerWidth,
			sx: `calc(100% - ${drawerWidth}px)`, ml: drawerWidth,
		},
		date: {
			flexGrow: 1
		},
		toolbar: theme.mixins.toolbar,
		avatar: {
			marginLeft: theme.spacing(2)
		}
	}
})

export default function Layout({children}:any) {
	const classes = useStyles()
	const dispatch = useAppDispatch();

	return (
		<DrawerContextProvider>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />

				<AppBar />

				<Drawer />

				<div className={ classes.page } >
					<div className={classes.toolbar}></div>
					{children}
				</div>

			</Box>
		</DrawerContextProvider>
	);

}
