import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container'
import Autocomplete from '@mui/material/Autocomplete';
import SendIcon from '@mui/icons-material/Send'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useAppDispatch, useAppSelector } from "../hooks";
import { BookmarkModel } from '../components/bookmarks/bookmarkModel';
import get_info from '../services/pageinfo'
import get_tags from '../services/tags'
import type {RootState} from '../store'

import { selectBookmarkById , useUpdateBookmarkMutation } from '../components/bookmarks/bookmarksSlice';

export default function Edit() {
  const [ updateBookmark, {isLoading} ] = useUpdateBookmarkMutation();
  const navigate = useNavigate()
  const {id} = useParams();
  const bm = useSelector( (state:RootState) => selectBookmarkById( state, id ?? '') );

  const [title, setTitle] = useState( bm?.title ?? '' )
  const [url, setUrl ] = useState(bm?.url ?? '');
  const [user, setUser ] = useState(bm?.user ?? '');
  const [create, setCreate ] = useState(bm?.created ?? '');
  const [tags, setTags ] = useState( bm?.tags ?? [] )
  const [description, setDescription] = useState(bm?.description ?? '');
  const [publik, setPublic] = useState(bm?.public ?? false)
  const [titleError, setTitleError] = useState(false)
  const [urlError, setUrlError] = useState(false)

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError]     = React.useState(false);
  const [formSent, setFormSent ]      = React.useState( false );
  const [alltags, setAllTags ]        = React.useState( [''])

  useEffect(() => {
    console.log( "Edit: useEffect() ")

    get_tags().then( (tags) => {
      console.log( "Tag number : ", tags.length )
      setAllTags(tags)
    });
  }, []);

  const handleCloseSuccess = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  }; 

  const handleCloseError = (event:any, reason:string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  }; 

  const checkUrl = async () => {

    const pageInfo = await get_info(url)
    setTitle( pageInfo.title);
    setDescription( pageInfo.description);
    setTags( pageInfo.tags.split(',') )
  }

  const bmOperationState = "READY"; //useAppSelector( getCurrentState)

  if( formSent ) {

   if( bmOperationState === 'READY') {
    setOpenSuccess( true )
   }
   //if( bmOperationState === 'ERROR') {
   // setOpenError( true )
   //}

   setFormSent( false )
  }

  const handleSubmit = async ( e : React.SyntheticEvent ) => {
    //e.preventDefault()
    setTitleError(false)
    setUrlError(false)

    if (title === '') {
      setTitleError(true)
    }
    if (url === '') {
      setUrlError(true)
    }

    if (title && url ) {
      // Convert to array
      console.log( "Taglist:", tags)
      let tags22 : string[] = [];
      tags.forEach( t => tags22.push(t.trim() ))

      const newBM : BookmarkModel = {
        url: url,
        title: title,
        description: description,
        public: publik,
        tags : tags22,
        user : user,
        id : id ?? '',
        created : create
      };

      try {	
        await( updateBookmark( newBM ) ).unwrap();
        setUrl('');
        setTitle('');
        setDescription('')
        setTags([])
        setPublic( false )
        navigate("/")
      }
      catch( err ) {
        console.error( "Failed to save bookmark : ", err );
      }

      setFormSent( true )
    }
  }

  const handlePublic = (e :  React.FormEvent<HTMLInputElement> ) => {
    setPublic( e.currentTarget.checked ); //  e.target.checked);
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h6" 
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Edit Bookmark
      </Typography>
      
      <form noValidate autoComplete="off" >
        <TextField 
          margin="dense"
          onChange={(e) => setUrl(e.target.value)}
          label="Bookmark URL" 
          variant="outlined" 
          color="secondary" 
          fullWidth
          value={url}
          required
          error={urlError}
        />
        <Button onClick={ () => {
          checkUrl()
        }}>
          Check
        </Button>
        <TextField 
          sx={{field: {marginTop: 20, marginBottom: 20, display: 'block' } }}
          onChange={(e) => setTitle(e.target.value)}
          label="Bookmark Title" 
          variant="outlined" 
          color="secondary" 
          margin="dense"
          value={title}
          fullWidth
          required
          error={titleError}
        />
        <TextField 
          margin="dense"
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          variant="outlined"
          color="secondary"
          multiline
          value={description}
          rows={4}
          fullWidth
        />

        <Autocomplete
            multiple
            freeSolo={true}
            id="tags"
            options={alltags}
            value={tags}
            onChange={(event, value) => setTags(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tags"
                placeholder="Tags"
              />
            )}
          />

        <FormControl margin="dense">
          <FormControlLabel control={ <Checkbox checked={publik} onChange= { handlePublic} />} label="Public" />
        </FormControl>

        <div>
        <Button 
          type="button" 
          color="secondary" 
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
          >
          Submit
        </Button>
        </div>

        <Snackbar
          message="Bookmark was added successfully"
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        />
        <Snackbar
          message="ERROR adding bookmark!!"
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        />

      </form>
    </Container>
  )
}
