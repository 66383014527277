import { createSlice, createAsyncThunk, PayloadAction , createSelector} from "@reduxjs/toolkit";
import type { UserInfo, UserModel, UserUpdateModel, UserLogin } from './UserModel';
//import users from "../../services/users";
import userService from '../../services/users';
import type { RootState, AppDispatch } from '../../store';

//type UserState = "LOADING" | "ADDING" | "DELETING" | "READY" | "ERROR";
/*
export interface UserInfo {
	name: string,
	username : string,
	token: string,
};*/

export class LoginInfo {
	password: string;
	username : string;

	constructor(  user:string, psw:string )
	{
		this.username = user;
		this.password = psw;
	}
};

export interface UsersState {
	user: UserInfo
	//  userState: UserState
}

const initialState: UsersState = {
	user: { name:'', username:'', token:'' },
	//  userState: "READY"
}

export const loginUser = createAsyncThunk(
	"users/login", 
	async (the_user: UserLogin ) => {
		try{
			const response = await userService.user_login( the_user );
			return await response ;
		}
		catch( error ) {
			console.log( error )
		}
	}
)

export const addUser = createAsyncThunk(
	"/users/create", 
	async (new_user: UserModel, thunkAPI ) => {
		const response = await userService.create( new_user );
		return response;
	}
)

export const updateUser = createAsyncThunk(
	"/users/update", 
	async (new_user: UserUpdateModel ) => {
		const response = await userService.update( new_user );
		return response;
	}
)

const usersSlice = createSlice( {
	initialState,
	name: "users",
	reducers: {
		setUser( state, action: PayloadAction< UserInfo >) {
			state.user = action.payload;
		},

		clearUser( state ) {
			state.user = initialState.user; 
		}

	},
	//extraReducers: (builder) => {
	//	builder.addCase( loginUser.fulfilled, (state, action: PayloadAction< UserInfo >) => {
	//		state.user = action.payload; // entities.push(action.payload)
	//	})
	//},
});

export const { setUser } = usersSlice.actions
export default usersSlice;
