import React, { useState, useEffect } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const MyAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert( props, ref ) 
{
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AlertParam {
	open: boolean;
	closeHandler() : void ;
    message:string;
    timeout: number;
};

export default function CustomAlert( props: AlertParam) {

    //const [openPIError, setOpenPIError] = React.useState(false);

    return (
        <Snackbar
          message={props.message}
          open={props.open}
          autoHideDuration={ props.timeout }
          onClose={ props.closeHandler }
        >
          <MyAlert onClose={ props.closeHandler } severity="error" sx={{ width: '100%' }}>
             { props.message }
          </MyAlert>
        </Snackbar>
    )
}

