import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import { CardContent, CardActions }  from '@mui/material/';
import IconButton from '@mui/material/IconButton'
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import ChipList from './ChipList'
import {selectBookmarkById} from './bookmarksSlice'
import {useAppSelector} from '../../hooks'

interface BookmarkProps {
	id: string;
  user:string;
	deleteHandler( id : string) : void ;
};

export default function  Bookmark(props : BookmarkProps) 
{
  const navigate = useNavigate()

  const bm = useAppSelector(state => selectBookmarkById(state,  props.id) ??
      {created:'',description:'',id:'',public:false, tags:[],title:'',url:'',user:''}  );
 
  var fullDesc = bm.description ?? '';
  fullDesc += "\n   Created:";
  fullDesc += bm.created

  return (
      <div>
      <Card elevation={3}>
        <CardContent>
          <Tooltip title={<Typography fontSize={14}>{fullDesc}</Typography >}>
            <Link href={ bm.url} underline="hover">
              { bm.title }
            </Link>
          </Tooltip>
          <ChipList adat ={bm.tags} />
        </CardContent>

      {  ( props.user === '') ? '' :
              <CardActions>
          <Tooltip title='Delete'>
              <IconButton onClick={ () => {  props.deleteHandler(props.id ) }} >
                <DeleteOutlined  />
              </IconButton>
          </Tooltip>
          <Tooltip title='Edit'>
              <IconButton onClick={ () => {  navigate("/edit/" + bm.id ) }} >
                <EditOutlined  />
              </IconButton>
          </Tooltip>
        </CardActions>
      }
      </Card>
    </div>      
  )
}
